import React, {useEffect, useRef, useState} from 'react';
import EditWindow from "@components/Tasks/TasksPageComponents/TaskCard/Header/EditWindow";
import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';
import {TaskProvider} from "@components/Tasks/AddTaskWindow/TaskContext";

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const EditMenu = ({task, isAutoTask, progress, isActive, onStopTask, onDeleteTask, setIsEditOpen}) => {
  const [isEditWindowOpen, setIsEditWindowOpen] = useState(false);
  const editMenuRef = useRef(null);

  const handleCloseTaskWindow = () => {
    setIsEditWindowOpen(false);
  }

  // Обработчик кликов по документу
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editMenuRef.current && !editMenuRef.current.contains(event.target)) {
        setIsEditOpen(false); // Закрываем меню при клике вне его
      }
    };

    // Добавляем слушатель кликов по документу
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Удаляем слушатель при размонтировании компонента
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMenuRef, setIsEditOpen]);

  return (
    <div ref={editMenuRef} className="task-card-edit-menu">
      {progress < 100 && (
        <div className="task-card-edit-menu-item" onClick={onStopTask}>
          {isActive ? (
            <>
              <span className="mr-5">Остановить</span>
              <HandySvg src={images[`stopTask.svg`]} className="logo-15x15"/>
            </>
          ) : (
            <>
              <span className="mr-5">Запустить</span>
              <HandySvg src={images[`startTask.svg`]} className="logo-15x15"/>
            </>
          )}
        </div>
      )}
      {progress < 100 &&
        <div className="task-card-edit-menu-item" onClick={() => setIsEditWindowOpen(true)}>
          <span className="mr-5">Изменить</span>
          <HandySvg src={images[`changeTask.svg`]} className="logo-15x15"/>
        </div>
      }
      {isEditWindowOpen &&
        <TaskProvider task={task} isAutoTask={isAutoTask}>
          <EditWindow handleClose={handleCloseTaskWindow} isEditWindow={true} isAutoTask={isAutoTask}/>
        </TaskProvider>}
      <div className="task-card-edit-menu-item" onClick={onDeleteTask}>
        <span className="mr-5">Удалить</span>
        <HandySvg src={images[`trash.svg`]} className="logo-15x15"/>
      </div>
    </div>
  );
};

export default EditMenu;
