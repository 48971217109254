import React, {Component} from 'react';
import {editTask} from '@components/shared/api';
import {TaskContext} from '@components/Tasks/AddTaskWindow/TaskContext';
import {AuthContext} from '@context/AuthContext';
import BlueRectangle from "@components/Tasks/AddTaskWindow/Default/BlueRectangle";
import EditTaskForm from "@components/Tasks/TasksPageComponents/TaskCard/Header/EditTaskForm";
// import './TaskWindow.css';

import {HandySvg} from 'handy-svg';
import ImageUtils from '@components/imageUtils';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

class EditWindow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorMessage: '',
      isSending: false, // Флаг блокировки отправки
    };
  }

  handleCurrentWindow = ({window}) => {
    this.setState(() => ({currentWindow: window}));
  };

  sendTasksToServer = async (authContext, taskContext) => {
    if (this.state.isSending) return; // Если задача уже отправляется, блокируем повторное нажатие

    const {logout} = authContext;
    const {taskData, errorHandler, fieldsChecker} = taskContext;

    if (fieldsChecker()) {
      this.setState({isSending: true}); // Устанавливаем флаг, чтобы показать, что задача отправляется
      try {
        const response = await editTask(
          taskData,
        );

        if (response.data.status) {
          this.props.handleClose();
        } else {
          if (response.data.msg === 'Dont have permission') {
            logout();
          } else {
            errorHandler(response.data.msg, response.data.field_error);
          }
        }
      } catch (error) {
        console.error('Ошибка при отправке задач:', error);
      } finally {
        this.setState({isSending: false}); // Разблокируем кнопку после завершения запроса
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseDown);
    document.body.classList.add('no-scroll'); // Блокируем прокрутку страницы
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.body.classList.remove('no-scroll'); // Разрешаем прокрутку страницы
  }

  handleMouseDown = (e) => {
    const taskWindow = e.target.closest('.task-window');
    const calendar = e.target.closest('.react-datepicker');

    if (!taskWindow && !calendar) {
      this.props.handleClose();
    }
  };

  ErrorWindow = (taskContext) => {
    const {isError, errorMessage, handleCloseError} = taskContext;

    return (
      isError &&
      <div className='error-msg-container'>
        <div className='error-msg-content'>
          <HandySvg src={images['error.svg']} className="logo-15x15 mr-5"/>
          <b className='mr-5'>Ошибка: </b>
          {errorMessage}
        </div>
        <HandySvg src={images['errorClose.svg']} className="error-msg-close mr-5" onClick={handleCloseError}/>
      </div>
    )
  };

  CurrentWindow = (authContext, taskContext) => {
    return (
      <div className="task-content">
        <EditTaskForm
          handleCurrentWindow={this.handleCurrentWindow}
          sendTasksToServer={() => this.sendTasksToServer(authContext, taskContext)}
          isSending={this.state.isSending}
          handleClose={this.props.handleClose}
        />
        <BlueRectangle/>
      </div>
    );
  };

  render() {
    return (
      <AuthContext.Consumer>
        {authContext => (
          <TaskContext.Consumer>
            {taskContext => (
              <div className="task-window-overlay">
                <div className="task-window">
                  {this.CurrentWindow(authContext, taskContext)}
                  {this.ErrorWindow(taskContext)}
                </div>
              </div>
            )}
          </TaskContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default EditWindow;
