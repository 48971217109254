import React, {Component} from 'react';
import {fetchTasks} from '@components/shared/api';
import { debounce } from 'lodash';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import TaskCard from './TasksPageComponents/TaskCard/TaskCard';
import TaskWindow from './AddTaskWindow/TaskWindow';
import TasksTitle from "@components/Tasks/TasksPageComponents/PageElements/TasksTitle";
import Pagination from "@components/Tasks/TasksPageComponents/PageElements/Pagination";
import LoadingScreen from "@components/Tasks/TasksPageComponents/PageElements/LoadingScreen";
import EmptyPage from "@components/Tasks/TasksPageComponents/PageElements/EmptyPage";
import { AuthContext } from '@context/AuthContext';
import { TaskProvider } from './AddTaskWindow/TaskContext';
import './Tasks.css';

class Tasks extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      isTaskWindowOpen: false,
      isFilterWindowOpen: false,
      isSearchWindowOpen: false,
      isAutoTask: false,
      loading: false,
      activeFilter: null,
      searchText: '',
      windowWidth: 0,

      tasks: [],           // Отображаемые задачи
      regularTasks: [],
      countRegularTasks: 0,   // Обычные задачи
      autoTasks: [],
      countAutoTasks: 0,        // Автозадачи
      taskPerPage: 15,
      pageNumber: 1,
      countPages: 0,
    };
    this.intervalId = null;

    // Дебаунсируемая функция для обработки поиска
    this.handleRefreshTasksDebounced = debounce(this.refreshTasks, 300);  // Задержка 300 мс
  }

  handleResize = () => (this.setState({ windowWidth: window.innerWidth }))
  
  handleEnterKey = (event) => {
    if (event.key === 'Enter' && document.activeElement.tagName === 'INPUT') {
      event.preventDefault();
      document.activeElement.blur(); // Снимаем фокус с активного поля
    }
  };

  componentDidMount() {
    this.fetchAllTasks();
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', this.handleEnterKey);
    this.setState({ windowWidth: window.innerWidth });

    this.intervalId = setInterval(() => {
      this.refreshTasks();
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.handleEnterKey);
    clearInterval(this.intervalId);
  }

  // Запрашиваем все задачи (обычные и автозадачи)
  fetchAllTasks = async () => {
    this.setState({ loading: true });

    try {
      // Запрашиваем обычные задачи
      const regularTasksResponse = await fetchTasks(
        false,
        0,
        this.state.taskPerPage
      );
      const autoTasksResponse = await fetchTasks(
        true,
        0,
        this.state.taskPerPage
      );

      // Проверяем статус и обновляем состояние
      if (regularTasksResponse.data.status === false || autoTasksResponse.data.status === false) {
        this.context.logout();
      } else {
        this.setState({
          regularTasks: regularTasksResponse.data.data,
          countRegularTasks: regularTasksResponse.data.count,
          countPages: Math.ceil(regularTasksResponse.data.count / this.state.taskPerPage),
          autoTasks: autoTasksResponse.data.data,
          countAutoTasks: autoTasksResponse.data.count,
          tasks: regularTasksResponse.data.data, // По умолчанию отображаем обычные задачи
          loading: false,
        });
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      this.setState({ loading: false });
    }
  };

  // Метод для обновления отображаемого списка задач
  changeTaskType = (newType) => {
    const isAutoTask = newType === 'autotask';
    if (isAutoTask !== this.state.isAutoTask) {
      this.setState({
        isAutoTask: isAutoTask,
        tasks: isAutoTask ? this.state.autoTasks : this.state.regularTasks,
        pageNumber: 1,
        countPages: isAutoTask ?
          Math.ceil(this.state.countAutoTasks / this.state.taskPerPage)
          :
          Math.ceil(this.state.countRegularTasks / this.state.taskPerPage),
      });
    }
  };

  // Обновляем данные для текущего типа задач
  refreshTasks = async (pageNumber = this.state.pageNumber, shouldScroll = false) => {
    try {
      this.setState({pageNumber: pageNumber});

      // Прокрутка экрана вверх только при смене страницы
      if (shouldScroll) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

      const response = await fetchTasks(
        this.state.isAutoTask,
        (pageNumber - 1) * this.state.taskPerPage,
        this.state.taskPerPage,
        this.state.searchText,
        this.state.activeFilter
      );

      if (response.data.status === false) {
        this.context.logout();
      } else {
        this.setState({
          tasks: response.data.data,
        });

        if (this.state.isAutoTask) {
          this.setState({
            autoTasks: response.data.data,
            countAutoTasks: response.data.count,
            countPages: Math.ceil(response.data.count / this.state.taskPerPage),
          });
        } else {
          this.setState({
            regularTasks: response.data.data,
            countRegularTasks: response.data.count,
            countPages: Math.ceil(response.data.count / this.state.taskPerPage),
          });
        }
      }
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
    }
  };

  handleOpenTaskWindow = () => {
    this.setState({ isTaskWindowOpen: true });
  };

  handleCloseTaskWindow = () => {
    this.setState({ isTaskWindowOpen: false });
    this.refreshTasks(1);
  };

  handleFilterInputChangeDebounce = (filter) => {
    this.setState({ activeFilter: this.state.activeFilter === filter ? '' : filter });
    this.handleRefreshTasksDebounced();
  }

  // Обработчик изменений в поле поиска
  handleSearchInputChangeDebounce = (searchText) => {
    this.setState({searchText: searchText});
    this.handleRefreshTasksDebounced();
  };


  TasksCards = () => (
    <>
      <div className="tasks-grid">
        {this.state.tasks.map(task => (
          <TaskCard key={task.id} task={task} isAutoTask={this.state.isAutoTask}/>
        ))}
      </div>
      <Pagination
        activePage={this.state.pageNumber}
        countPages={this.state.countPages}
        taskPerPage={this.state.taskPerPage}
        onPageChange={(pageNumber) => this.refreshTasks(pageNumber, true)}
        onItemsPerPageChange={(countItems) => {
          this.setState({taskPerPage: countItems, pageNumber: 1}, () => {
            this.refreshTasks(1);
          });
        }}
      />
    </>
  );

  render() {
    return (
      <>
        <Header />
        <div className="tasks">
          <TasksTitle
            isAutoTask={this.state.isAutoTask}
            changeTaskType={this.changeTaskType}
            handleOpenTaskWindow={this.handleOpenTaskWindow}
            handleSearchInputChangeDebounce={this.handleSearchInputChangeDebounce}
            activeFilter={this.state.activeFilter}
            handleFilterInputChangeDebounce={this.handleFilterInputChangeDebounce}
          />

          {this.state.loading ? (
            <LoadingScreen/>
          ) : this.state.tasks.length === 0 ? (
            <EmptyPage onCreateTask={this.handleOpenTaskWindow}/>
          ) : (
            <this.TasksCards/>
          )}

          {this.state.isTaskWindowOpen && (
            <TaskProvider isAutoTask={this.state.isAutoTask}>
              <TaskWindow handleClose={this.handleCloseTaskWindow}/>
            </TaskProvider>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

export default Tasks;
