import React, { createContext, useState } from 'react';

// Создает контекст задачи
export const TaskContext = createContext();

// Предоставляет контекст задачи
export const TaskProvider = ({children, task, isAutoTask}) => {
  // Конструирует объект ошибок
  const ErrorsConstructor = () => ({
    subs: false,
    link: false,
    spread: false,
    count_actions: false,
    interval: false,
    time: false,
    rangesError: false,
  });

  // Возвращает данные для недельного календаря по умолчанию
  const defaultWeeklyData = () => [
    { shortName: 'Пн', fullName: 'Понедельник', apiName: 'monday', isActive: false, times: [] },
    { shortName: 'Вт', fullName: 'Вторник', apiName: 'tuesday', isActive: false, times: [] },
    { shortName: 'Ср', fullName: 'Среда', apiName: 'wednesday', isActive: false, times: [] },
    { shortName: 'Чт', fullName: 'Четверг', apiName: 'thursday', isActive: false, times: [] },
    { shortName: 'Пт', fullName: 'Пятница', apiName: 'friday', isActive: false, times: [] },
    { shortName: 'Сб', fullName: 'Суббота', apiName: 'saturday', isActive: false, times: [] },
    { shortName: 'Вс', fullName: 'Воскресенье', apiName: 'sunday', isActive: false, times: [] },
  ];

  // Конструирует данные задачи по умолчанию
  const TaskDataConstructor = () => ({
    task_type: task ? task.task_type : 'views',
    target_url: task ? task.target_url : '',
    channel_id: '',
    count_actions: task ? task.count_actions : 0,
    type_count: task ? task.type_cout : 'num',
    task_obj: task ? task.task_obj : [],
    task_time: task ? task.task_time : 3600,
    time_controll: task ? task.time_controll : {},
    time: 1,
    timeUnit: 'hours',
    countIntervals: task ? task.behavior.length : 5,
    behavior: task ?
      task.behavior :
      Array.from({length: 5}, () => 0),
    bot_group: task ? task.bot_group : '',
    errors: ErrorsConstructor(),
    isAutoTask: isAutoTask,
    spread: task ? task.spread : '',
    calendarMode: 'week',
    calendarData: [],
    weeklyData: defaultWeeklyData(),
  });

  // Инициализирует состояние данных задачи
  const [taskData, setTaskData] = useState(TaskDataConstructor());

  // Возвращает объект времени задачи по умолчанию
  const defaultTime = () => {
    return {
      startTime: '',
      endTime: '',
      isAllDay: false,
      count: taskData.count_actions,
      countType: taskData.type_count,
    }
  }

  // Округляет значения поведения
  const roundBehaviorCount = (behavior) => {
    let overflow = 0;
    behavior.forEach((value, i) => {
      overflow += value % 1;
      behavior[i] = Math.floor(value);
      if (overflow >= 1) {
        behavior[i] += 1;
        overflow -= 1;
      }
    });
    if (overflow >= 0.5)
      behavior[behavior.length - 1] += 1
    return behavior;
  }

  // Обновляет поведение на основе количества интервалов и действий
  const updateBehavior = (behavior, countIntervals, count_actions) => {
    const percentageRatio = [];
    const sum = behavior.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (sum !== 0) {
      behavior.forEach((value) => percentageRatio.push(count_actions * (value / sum)));
    } else {
      for (let i = 0; i < countIntervals; i++) {
        percentageRatio.push(count_actions / countIntervals);
      }
    }
    return roundBehaviorCount(percentageRatio);
  };

  // Перестраивает поведение при изменении количества интервалов и действий
  const rebuildBehavior = (countIntervals, count_actions) => {
    const behavior = taskData.behavior.slice(0, Math.min(taskData.behavior.length, countIntervals))
    for (let i = 0; i < countIntervals - behavior.length; i++) {
      behavior.push(0);
    }
    handleTaskDataChange('behavior', updateBehavior(behavior, countIntervals, count_actions));
  };

  // Обрабатывает изменения в данных задачи
  const handleTaskDataChange = (field, value) => {
    const isCountIntervalsChanged = field === 'countIntervals' && value !== taskData.countIntervals;
    const isCountActionsChanged = field === 'count_actions' && value !== taskData.count_actions;

    if (isCountIntervalsChanged) {
      rebuildBehavior(parseInt(value), taskData.count_actions);
    } else if (isCountActionsChanged) {
      rebuildBehavior(taskData.countIntervals, parseInt(value));
    }
  
    setTaskData(prevTaskData => ({
      ...prevTaskData,
      [field]: value,
    }));
  };

  // Рассчитывает поведение в зависимости от времени
  const handleBehaviour = () => {
    const timeToInterval = Math.round(taskData.task_time / taskData.countIntervals * 1000);
    const now = Date.now();
    const ans = [[now, taskData.behavior[0]]];
    for (let i = 1; i < taskData.countIntervals; i++) {
      ans.push([ans[i - 1][0] + timeToInterval, taskData.behavior[i]]);
    }
    return ans;
  };

  // Возвращает строковое представление диапазона времени
  const handleGetRange = (dayValue) => {
    const months = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сент.', 'октября', 'ноября', 'декабря'
    ];

    const startMonth = months[dayValue.startDate.getMonth()];
    const endMonth = months[dayValue.endDate.getMonth()];
    const stringStartDate = `${dayValue.startDate.getDate()} ${startMonth}`;
    const stringEndDate = `${dayValue.endDate.getDate()} ${endMonth}`;

    if (stringStartDate === stringEndDate)
      return stringStartDate;
    return `${stringStartDate} - ${stringEndDate}`;
  };

  // Управляет состоянием ошибок
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Устанавливает ошибку для конкретного поля
  const setFieldError = (field) => {
    if (field)
      setTaskData((prevTaskData) => ({
        ...prevTaskData,
        errors: {
          ...prevTaskData.errors,
          [field]: true,
        },
      }));
  };

  // Закрывает сообщение об ошибке
  const handleCloseError = () => {
    setIsError(false);
    handleTaskDataChange('errors', ErrorsConstructor());
  };

  // Обрабатывает ошибки
  const errorHandler = (msg, fieldError = null) => {
    handleCloseError();
    setIsError(true);
    setErrorMessage(msg);
    setFieldError(fieldError);
  };

  // Проверяет временные диапазоны на корректность
  const timeRangesChecker = (rangesData) => {
    for (let rangeIndex = 0; rangeIndex < rangesData.length; rangeIndex++) {
      let range = rangesData[rangeIndex];
      if (range.isActive === undefined || range.isActive === true)
        for (let timeIndex = 0; timeIndex < range.times.length; timeIndex++) {
          let time = range.times[timeIndex];
          if (time.isAllDay === false && (time.startTime === '' || time.endTime === '')) {
            return { status: false, rangeIndex: rangeIndex, timeIndex: timeIndex };
          }
        }
    }
    return { status: true };
  };

  // Проверяет обязательные поля и временные диапазоны задачи
  const fieldsChecker = () => {
    const errorConditions = [
      {
        condition: taskData.count_actions === 0,
        message: 'Укажите количество действий',
        field: 'count_actions',
      },
      {
        condition: taskData.target_url === '',
        message: 'Укажите ссылку',
        field: 'link',
      },
      {
        condition: taskData.task_time === 0,
        message: 'Время не может быть равно нулю',
        field: 'time',
      },
    ];
  
    // Проверяет базовые условия
    for (let { condition, message, field } of errorConditions) {
      if (condition) {
        errorHandler(message, field);
        return false;
      }
    }
  
    // Проверяет временные диапазоны
    const rangeCheckResult = timeRangesChecker(
      taskData.calendarMode === 'week' ? taskData.weeklyData : taskData.calendarData
    );
  
    if (!rangeCheckResult.status) {
      const errorMessage = taskData.calendarMode === 'week'
        ? `Поля начала и окончания в ${taskData.weeklyData[rangeCheckResult.rangeIndex].fullName} не должны быть пустыми`
        : `Поля начала и окончания в ${handleGetRange(taskData.calendarData[rangeCheckResult.rangeIndex])} не должны быть пустыми`;
  
      errorHandler(errorMessage);
      return false;
    }
    return true;
  };
  

  return (
    <TaskContext.Provider value={{
      taskData,
      handleTaskDataChange,
      roundBehaviorCount,
      updateBehavior,
      handleBehaviour,
      defaultTime,
      handleGetRange,
      isError,
      errorMessage,
      errorHandler,
      handleCloseError,
      setFieldError,
      fieldsChecker,
    }}>
      {children}
    </TaskContext.Provider>
  );
};
